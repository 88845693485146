









































// CORE
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

// INTERFACES
import { NameValueResource } from '@/store/types'

// STORE
import OrderModule from '@/store/modules/order'

@Component
export default class ServicesFormPart extends Vue {
  @Prop({ default: 'Техническое обслуживание' })
  readonly requestName!: string

  @Prop({ default: null })
  readonly title!: string

  @Prop({ default: true })
  readonly addMore!: boolean

  @PropSync('other', { default: 'Чистка ковров' })
  readonly innerOtherValue!: string

  @PropSync('items', { default: () => ([]) })
  readonly innerItems!: NameValueResource[]

  private drawerOptions: any = null

  private mounted () {
    let type = ''

    if (this.requestName === 'Техническое обслуживание') {
      type = OrderModule.maintenanceType
    }

    this.drawerOptions = {
      name: this.requestName,
      type,
      btnLabel: 'Сохранить',
      heading: 'Новая заявка',
    }
  }
}
